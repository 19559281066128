// Fonts
@import url("https://fonts.googleapis.com/css?family=Ubuntu:300,400,500,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;400;600;700&display=swap");
@import "~@fortawesome/fontawesome-free/css/all.min.css";

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";

//Select 2
@import "~select2/dist/css/select2.min.css";
@import "~@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.min.css";

// jQuery UI
@import "~jquery-ui/themes/base/all.css";

//Lity
@import "~lity/dist/lity.css";

// Data Ranger Picker
@import "~daterangepicker/daterangepicker.css";

// Datetimepicker
@import "~jquery-datetimepicker/build/jquery.datetimepicker.min.css";

// Datatables
@import "~datatables/media/css/jquery.dataTables.css";
@import "~datatables.net-bs4/css/dataTables.bootstrap4.min.css";
@import "~datatables.net-autofill-bs4/css/autoFill.bootstrap4.min.css";
@import "~datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css";
@import "~datatables.net-rowreorder-bs4/css/rowReorder.bootstrap4.min.css";
@import "~datatables.net-select-bs4/css/select.bootstrap4.min.css";

.dataTables_processing {
  top: 7px !important;
  margin: 0px 15px !important;
  width: calc(100% - 30px) !important;
  left: 0px !important;
  padding: 10px !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  background: $primary !important;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    $primary 25%,
    $primary 75%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  color: $white !important;
}

$defColorName: "primary", "secondary", "success", "info", "warning", "danger", "light", "dark", "silver";
$defColor: $primary, $secondary, $success, $info, $warning, $danger, $light, $dark, $silver;

$custom-file-text: (
  en: "Browse",
  pt: "Procurar",
);

$shadow: 1px 1px 1px #{$dark + "99"};

a,
a:hover {
  text-decoration: none;
}

a.text-white:hover {
  color: lighten($secondary, 10%) !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-family-heading;
  font-style: italic;
}

.fas,
.far {
  font-family: "Font Awesome 5 Free";
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

.h1.fa,
.h1.fas,
.h1.far,
.h2.fa,
.h2.fas,
.h2.far,
.h3.fa,
.h3.fas,
.h3.far,
.h4.fa,
.h4.fas,
.h4.far,
.h5.fa,
.h5.fas,
.h5.far,
.h6.fa,
.h6.fas,
.h6.far {
  font-weight: 900;
}

.font-smaller {
  font-size: 0.8em;
}

.font-larger {
  font-size: 1.2em;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

body {
  background-image: url("/images/banner-fundo.png");
  background-size: 100% auto;
  background-repeat: repeat-x;
}

div[vw] {
  top: 70%;
}

$card-news-height: 350px;

.card {
  text-align: left !important;
  border-radius: 0px;
}

.card-news {
  position: relative;
  border: 0px;
  border-radius: 0px;

  .card-title {
    position: absolute;
    z-index: 2;
    bottom: 0px;
    left: 0px;
    width: 100%;
    padding: 0px;

    .card-bar {
      width: 100%;
      background: $dark;
      background: linear-gradient(0deg, #{$dark + "ee"} 0%, #{$dark + "dd"} 60%, #{$dark + "00"} 100%);
      padding: 60px 15px 15px 15px;
    }
  }

  .card-img {
    position: relative;
    width: 100%;
    min-height: $card-news-height;
    overflow: hidden;
    background-color: $gray-200;

    figure {
      min-height: $card-news-height;
    }
  }
}

$card-others-height: 110px;

.card-others {
  .card-img {
    figure {
      height: $card-others-height;
    }
  }
}

@include media-breakpoint-up(md) {
  $card-news-height: 200px;

  .card-news {
    min-height: $card-news-height;

    .card-img {
      min-height: $card-news-height;

      figure {
        min-height: $card-news-height;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  $card-others-height: 200px;

  .card-others {
    .card-img {
      figure {
        height: $card-others-height;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  $card-news-height: 280px;

  .card-news {
    min-height: $card-news-height;

    .card-img {
      min-height: $card-news-height;

      figure {
        min-height: $card-news-height;
      }
    }
  }
}

nav {
  font-size: 14px;
}

.bg-none {
  background: transparent;
  background-image: none;
}

.text-small,
.text-small a {
  font-size: 0.8em;
  line-height: 1.2rem;
}

.text-editor {
  p,
  ol,
  ul,
  pre,
  blockquote,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    padding-bottom: 16px;
  }

  h1 {
    padding-bottom: 16px;
  }
}

@include media-breakpoint-up(xs) {
  .h-auto {
    height: auto;
  }
}

$wdouble: 4px;

.border-0 {
  border: 0px none;
}

.border-2 {
  border-width: $wdouble !important;
}

.corner-0 {
  border-radius: 0rem !important;
}

.corner-1 {
  border-radius: 0.75rem !important;
}

.corner-2 {
  border-radius: 1.5rem !important;
}

.corner-3 {
  border-radius: 10rem !important;
}

.card {
  &.border-2 {
    border-width: $wdouble !important;
    border-style: solid !important;

    @for $i from 0 to length($defColorName) {
      &.border-#{nth($defColorName, $i+1)} {
        border-color: nth($defColor, $i + 1) !important;
      }
    }
  }

  &.corner-2 {
    .card-header {
      border-radius: 1.2rem 1.2rem 0rem 0rem;
    }

    .card-body {
      border-radius: 0rem;
    }

    .card-footer {
      border-radius: 0rem 0rem 1.2rem 1.2rem;
    }
  }

  &.bg-transparent {
    @for $i from 0 to length($defColorName) {
      &.border-#{nth($defColorName, $i+1)} {
        &:hover {
          background-color: #{nth($defColor, $i + 1)}60 !important;
        }
      }
    }
  }
}

.autocomplete-items {
  border: 1px solid $gray-400;
  padding: 2px 5px;
  cursor: pointer;

  div:hover {
    background-color: $gray-200;
  }
}

.box-shadow {
  box-shadow: $shadow;
}

.text-shadow {
  text-shadow: $shadow;
}

tfoot {
  font-weight: bold;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\F105";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.cookie-consent {
  border-top: 3px solid $secondary;
  position: fixed;
  z-index: 1000000;
  bottom: 0px;
  background-color: $primary;
  width: 100%;
  padding: 10px 20px;
  color: $light;
  display: flex;
  justify-content: center;

  button {
    background-color: $tertiary;
    border: 1px solid $tertiary;
    border-radius: 5px;
    color: #fff;
    padding: 0px 20px;
    margin-left: 40px;
  }

  button:hover {
    background-color: lighten($tertiary, 10%);
  }

  a {
    color: $tertiary;
  }

  a:hover {
    color: lighten($tertiary, 10%);
  }
}

@include media-breakpoint-up(sm) {
}

@include media-breakpoint-up(md) {
}

@include media-breakpoint-up(lg) {
}

@include media-breakpoint-up(xl) {
}

/** Ajustes da migração das notícias antigas **/
.alignleft {
  float: left;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.alignright {
  float: right;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

img.size-full {
  width: 100%;
  height: auto;
}

.hover-img {
  transition: all 0.3s ease-in-out;
  width: 100%;
}

.hover-img:hover {
  filter: grayscale(100%);
  transition: all 0.3s ease-in-out;
  width: 120%;
}

.z-index-1 {
  z-index: 1;
}

.z-index-10 {
  z-index: 10;
}

.z-index-100 {
  z-index: 100;
}

.media {
  display: block;
}

.card-group {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.wrapper {
  width: 100%;
}

.card .card-img {
  bottom: 300px;
}

#card-image {
  .card-img {
    width: 100%;
  }
}

.elements {
  width: 100%;
  height: 100%;
  vertical-align: middle;
}

.box a .fas {
  font-size: 26;
  color: $gray-500;
}

.fa-eye,
.fa-eye-slash {
  top: -35px;
  right: 0px;
  margin: 5px;
  padding: 5px;
}

.fa-eye.eye-sm,
.fa-eye-slash.eye-sm {
  top: -30px;
}

.is-invalid {
  background-image: none !important;
}
