// App
@import "app";

@import "~owl.carousel/dist/assets/owl.carousel.css";

body {
    font-size: 0.9rem;
    background-image: url("/images/back-topo.png");
    background-size: 350% auto;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: top center;

    @include media-breakpoint-up(sm) {
        background-size: 280% auto;
    }
    @include media-breakpoint-up(md) {
        background-size: 200% auto;
    }
    @include media-breakpoint-up(lg) {
        background-size: 150% auto;
    }
    @include media-breakpoint-up(xl) {
        background-size: 130% auto;
    }

}

/* Acessibilidade */
.hc {
    background-color: #303030;
    -webkit-filter: invert(100%);
    -moz-filter: invert(100%);
    -ms-filter: invert(100%);
    -o-filter: invert(100%);
    filter: invert(100%);
}

#header {

    #logo {
        img {
            width: 35%;

            @include media-breakpoint-up(md) {
                width: 75%;
            }

            @include media-breakpoint-up(lg) {
                width: 50%;
            }
        }
    }

    #menuPrincipal {
        height: 60px;

        @include media-breakpoint-down(md) {
            height: 46px;
        }

        > div {
            top: -5px;
        }

        .navbar-toggler,
        .navbar-toggler:focus {
            border: 0px none;
            border-radius: 0px;
        }

        #navbarPrincipal {
            @include media-breakpoint-down(md) {
                margin-top: -6px;

                ul#principal {
                    background-color: transparent;
                    padding: 0px 19px;
                }
            }

            .nav.nav-pills .nav-link.active,
            .nav.nav-pills .show > .nav-link {
                color: $black;
                background-color: transparent;
            }

            .nav.nav-pills .nav-link.active:hover,
            .nav.nav-pills .show > .nav-link:hover {
                color: $tertiary;
                background-color: transparent;
            }

            .nav {

                .nav-item {
                    font-family: $font-family-heading;
                    font-weight: 400;
                    font-style: italic;
                    font-size: 1rem;

                    @include media-breakpoint-up(md) {
                        font-size: 1.1rem;
                    }
                    @include media-breakpoint-up(lg) {
                        font-size: 1.4rem;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 1.4rem;
                    }

                    .nav-link {
                        color: $tertiary;
                        padding: 10px;

                        @include media-breakpoint-up(md) {
                            padding: 23px 14px;
                        }
                        @include media-breakpoint-up(lg) {
                            padding: 23px;
                        }
                        @include media-breakpoint-up(xl) {
                            padding: 24px;
                        }
                    }

                    .dropdown-menu {
                        border-radius: 0px;
                        border: 0px none;
                        top: 70px;
                        min-width: 100%;
                        font-size: 1rem;

                        .dropdown-item:hover {
                            background-color: $primary;
                            color: $light;
                        }

                        @include media-breakpoint-down(md) {
                            position: relative;
                            top: 0;
                            left: 0;
                        }
                    }

                    .dropdown-menu.show {
                        :parent.nav-item {
                            color: $secondary;
                            background-color: $primary;
                        }
                    }
                }

                .nav-item:hover {
                    color: $secondary;
                    background-color: $primary;

                    .nav-link:hover {
                        transition: all 0.15s ease-in-out;
                        color: $tertiary;
                    }
                }
            }
        }
    }
}

#carousel-box {
    #jogador {
        z-index: 2;
        right: 0;
        top: 40px;
        pointer-events: none;

        img {
            @include media-breakpoint-down(lg) {
                width: 75%;
            }

            @include media-breakpoint-down(md) {
                width: 50%;
            }

            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
    }
}

#newsPrincipal {
    #title {
        margin-top: 20px;
        margin-right: 40px;

        @include media-breakpoint-up(md) {
            margin-right: 0px;
            margin-top: 75px;
            z-index: 1;
            position: absolute;
        }
    }

    #cards {
        @include media-breakpoint-up(md) {
            margin-top: 145px;
        }
    }
}

#nossaHistoria {
    .title {
        font-size: 1.8rem;
    }

    .text-muted {
        font-size: 1rem;
    }
    @include media-breakpoint-up(md) {
        .title {
            font-size: 1.4rem;
        }

        .text-muted {
            font-size: 0.9rem;
        }
    }
    @include media-breakpoint-up(lg) {
        .title {
            font-size: 2rem;
        }

        .text-muted {
            font-size: 1.1rem;
        }
    }
}

.bg-skew {
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position-x: -300px;
}

#search::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $secondary;
    opacity: 1; /* Firefox */
}

#search:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $secondary;
}

#search::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $secondary;
}

.termos {
    height: 200px;
    overflow: scroll;
}

#info {
    background-color: $tertiary;

    a {
        color: $white;
    }

    .item {
        background: linear-gradient(to bottom right, $primary, $tertiary);

        .fas {
            font-size: 3rem;
        }

        p {
            font-size: 1.2rem;
            color: $secondary;
        }
    }
}

#orcamento {
    background-color: $indigo;
    color: $light;
    background: linear-gradient(to bottom right, $blue, $indigo);

    a {
        padding: 15px 30px;
        display: inline-block;
        -webkit-box-shadow: 8px 2px 3px 2px $primary;
        box-shadow: 8px 2px 3px 2px $primary;
        font-family: $font-family-heading;
        font-size: 1.5rem;
        background-color: $orange;
        color: $light;
        width: 70%;
    }

    @include media-breakpoint-up(lg) {
        a {
            width: 50%;
        }
    }

    span {
        display: block;
    }

    .container {
        padding-top: 40px;
        padding-bottom: 40px;

        img {
            width: 50%;
            margin-bottom: 30px;
        }

        .orcamento-box {
            background-image: none;
        }
    }
}

$orcamento-height: 500px;

@media (min-width: 768px) {
    #orcamento {
        height: $orcamento-height;

        .container {
            height: $orcamento-height;
            position: relative;
            padding-top: 0px;
            padding-bottom: 0px;

            img {
                width: 400px;
                margin-bottom: 0px;
            }

            .orcamento-box {
                background-image: url("/images/orcamento-listras.png");
                background-size: auto 100%;
                background-repeat: no-repeat;
                background-position: right;
                height: $orcamento-height;
                position: absolute;
                top: 30px;
            }
        }
    }
}

.search-box {
    width: 220px;
    display: flex;
    background: $gray-300;

    .search {
        width: 100%;
        padding: 10px;
        color: #303030;
        background-color: transparent;
        border: none;
        color: $gray-500;
        font-family: $font-family-heading;
        font-style: italic;
    }

    .search:hover {
        color: $black;
    }

    .search:focus {
        outline: none;
    }

    .search-btn {
        border: 0px none;
        background: $gray-300;
        color: $gray-500;
        font-size: 20px;
    }
}

.pl-md-5 {
    margin-right: 40px;
}

.linha-horizontal {
    border: 2px solid $primary;
}

#title1 {
    color: $primary;

    .ref {
        font-size: 0.8rem;
    }
}

#preco {
    color: $gray-600;
    font-weight: 400;
    font-size: 40px;
    margin-top: -30px;

    .de {
        font-size: 0.3em;
    }

    .promo {
        margin-top: -3px;

        span {
            display: inline-block;
        }

        .por {
            font-size: 0.3em;
            padding-top: 1.8em;
            padding-right: 3px;
        }

        .rs {
            font-size: 0.7em;
            margin-right: -7px;
        }

        .centavos {
            font-size: 0.6em;
        }
    }
}

#buttonProduct {
    margin-right: 30px;

    a {
        padding: 8px 16px;
        display: inline-block;
        font-family: $font-family-heading;
        font-size: 1.2rem;
        color: $indigo;
        background-color: $light;
        border: 2px solid $indigo;
    }

    a:hover {
        color: $orange;
        border: 2px solid $orange;
    }

    span {
        display: block;
    }
}

#recipeCarousel {
    .d-flex {
        color: $primary;
        font-family: $font-family-heading;
        margin-bottom: 5px;
    }

    .img-fluid {
        padding: 40px;
    }

    .card-body {
        padding: 0%;
    }

    .card {
        margin: 10px 0;
        border-radius: 10px;
        box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
    }

    .badge {
        background-color: $orange;
        width: 60px;
        margin-left: 170px;
    }

    ol {
        top: 490px;

        li {
            width: 20px;
            height: 20px;
            background-color: $orange;
        }
    }
}

.maps {
    iframe {
        margin-bottom: -7px;
    }
}

.btn-whatsapp {
    position: fixed;
    width: 50px;
    height: 50px;
    bottom: 40px;
    right: 40px;
    background-color: $whatsapp;
    color: $light;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 1px 1px 2px #888;
    z-index: 1000;

    &:hover {
        color: $quinary;
    }

    i {
        margin: 11px;
    }
}

#navbarMini {
    a {
        color: $light;
    }
}

.z-index-1 {
    z-index: 1;
}

.z-index-10 {
    z-index: 10;
}

.skew {
    -moz-transform: skew(320deg);
    -webkit-transform: skew(320deg);
    -o-transform: skew(320deg);
    -ms-transform: skew(320deg);
    transform: skew(320deg);
}

.anti-skew {
    -moz-transform: skew(-320deg);
    -webkit-transform: skew(-320deg);
    -o-transform: skew(-320deg);
    -ms-transform: skew(-320deg);
    transform: skew(-320deg);
}
