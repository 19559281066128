// Body
$body-bg: #ffffff;

// Typography
$font-family-sans-serif: "Ubuntu", sans-serif;
$font-family-heading: "Oswald", sans-serif;
$line-height-base: 1.6;
$font-size-base: 0.813rem;

// LAYOUT VARS
$topbar-height: 80px;
$topbar-height-mobile: 70px;

$sidebar-left-width: 120px;
$sidebar-left-width-mobile: 90px;
$sidebar-left-secondary-width: 220px;
$sidebar-left-secondary-width-mobile: 190px;
$side-clearance: 20px;

$white: #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #d3dae1 !default;
$gray-400: #bac2cb !default;
$gray-500: #808b96 !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #282c30 !default;
$gray-900: #1c2023 !default;
$black: #000 !default;

// Colors
$blue: #262163;
$indigo: #7572dd;
$purple: #9561e2;
$pink: #e66fc8;
$red: #d71920;
$orange: #ff6801;
$yellow: #ffcc00;
$dark-green: #37534e;
$green: #a1c552;
$light-green: #c8ef8f;
$teal: #00ccff;
$cyan: #0099ff;
$gold: #b8994e;
$dark-gold: #755509;

$primary: $green !default;
$secondary: $light-green !default;
$tertiary: $dark-green !default;
$quaternary: $gold !default;
$quinary: $dark-gold !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$ashen: $gray-200 !default;
$silver: $gray-400 !default;
$graphite: $gray-600 !default;
$deep: $gray-800 !default;
$dark: $black !default;

// GLOBAL COLORS
$facebook: rgb(55, 101, 201);
$google: rgb(236, 65, 44);
$twitter: rgb(3, 159, 245);
$instagram: rgb(193, 53, 132);
$linkedin: rgb(0, 119, 181);
$dribble: #ea4c89;
$youtube: #c4302b;
$whatsapp: #2fb140;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "quaternary": $quaternary,
  "quinary": $quinary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "ashen": $ashen,
  "silver": $silver,
  "graphite": $graphite,
  "deep": $deep,
  "black": $black,
  "dark": $dark,
  "red": $red,
  "facebook": $facebook,
  "google": $google,
  "twitter": $twitter,
  "instagram": $instagram,
  "linkedin": $linkedin,
  "dribble": $dribble,
  "youtube": $youtube,
  "whatsapp": $whatsapp,
);

.text-xsmall,
.text-xsmall a {
  font-size: 0.6rem !important;
  line-height: 0.8rem;
}

.text-small,
.text-small a {
  font-size: 0.8rem !important;
  line-height: 1.2rem;
}

.text-large,
.text-large a {
  font-size: 1.2rem !important;
  line-height: 1.4rem;
}

.text-xlarge,
.text-xlarge a {
  font-size: 1.8rem !important;
  line-height: 2rem;
}

.text-xxlarge,
.text-xxlarge a {
  font-size: 2.5rem !important;
  line-height: 2.5rem;
}

.line-0 {
  line-height: 0px;
}

.pointer {
  cursor: pointer !important;
}

.no-pointer {
  cursor: default !important;
}

$fsizes: (
  "1": 1.75rem,
  "2": 1.5rem,
  "3": 1.25rem,
  "4": 1rem,
  "5": 0.75rem,
  "6": 0.5rem,
);

@each $fs, $value in $fsizes {
  .size#{$fs} {
    font-size: $value !important;
  }
}

$bwidth: (
  "1": 1px,
  "2": 2px,
  "3": 3px,
  "4": 4px,
  "5": 5px,
);

@each $bw, $value in $bwidth {
  .border-#{$bw} {
    border-width: $value !important;
    border-style: solid !important;
  }

  .border-t-#{$bw} {
    border-top-width: $value !important;
    border-top-style: solid !important;
  }

  .border-l-#{$bw} {
    border-left-width: $value !important;
    border-left-style: solid !important;
  }

  .border-r-#{$bw} {
    border-right-width: $value !important;
    border-right-style: solid !important;
  }

  .border-b-#{$bw} {
    border-bottom-width: $value !important;
    border-bottom-style: solid !important;
  }
}

@each $name, $value in $theme-colors {
  .border-#{$name} {
    border-color: $value;
  }

  .bg-h-#{$name}:hover {
    background-color: $value !important;
  }

  a.link-#{$name} {
    color: $value !important;
  }

  a.link-#{$name + ":hover"},
  a.link-h-#{$name + ":hover"} {
    color: darken($value, 30%) !important;
  }
}

a.link-light:hover {
  color: $secondary !important;
}

$bradius: (
  "0": 0rem,
  "1": 0.25rem,
  "2": 0.5rem,
);

@each $bw, $value in $bradius {
  .corner-#{$bw} {
    border-radius: $value !important;
  }
  .corner-tl-#{$bw} {
    border-top-left-radius: $value !important;
  }
  .corner-tr-#{$bw} {
    border-top-right-radius: $value !important;
  }
  .corner-bl-#{$bw} {
    border-bottom-left-radius: $value !important;
  }
  .corner-br-#{$bw} {
    border-bottom-right-radius: $value !important;
  }

  .corner-t-#{$bw} {
    border-top-left-radius: $value !important;
    border-top-right-radius: $value !important;
  }
  .corner-r-#{$bw} {
    border-top-right-radius: $value !important;
    border-bottom-right-radius: $value !important;
  }
  .corner-b-#{$bw} {
    border-bottom-left-radius: $value !important;
    border-bottom-right-radius: $value !important;
  }
  .corner-l-#{$bw} {
    border-top-left-radius: $value !important;
    border-bottom-left-radius: $value !important;
  }
}

//CKEdior
figure.image {
  img {
    width: 100%;
  }

  figcaption {
    padding: 5px;
    background-color: $gray-200;
    font-size: 11px;
    color: #999;
  }
}

.image-style-align-right {
  float: right;
  margin: 5px 0px 5px 15px;

  figcaption {
    text-align: right;
  }
}

.image-style-align-left {
  float: left;
  margin: 5px 15px 5px 0px;

  figcaption {
    text-align: left;
  }
}

.play.fas,
.play.far {
  opacity: 70%;
  font-size: 57px;
  text-shadow: 2px 2px #000;
  border-width: 6px;
  border-style: solid;
  padding: 0px 22px;
  border-radius: 300px;
  background-color: #000;
  width: 70px;
  height: 70px;
}

a.hover-img {
  img {
    transition: all 0.3s ease-in-out;
    width: 100%;
  }
  img:hover {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    width: 120% !important;
    min-width: 120% !important;
  }
}

a.hover-img {
  img {
    transition: all 0.3s ease-in-out;
    width: 100%;
  }
  img:hover {
    filter: grayscale(100%);
    transition: all 0.3s ease-in-out;
    width: 120% !important;
    min-width: 120% !important;
  }
}

.box-shadow {
  -webkit-box-shadow: 4px 4px 5px -2px rgba(0,0,0,0.85); 
  box-shadow: 4px 4px 5px -2px rgba(0,0,0,0.85);
}

.text-shadow {
  text-shadow: 1px 1px 2px rgba(0,0,0,0.85); 
}

.alert-box {
  position: fixed;
  z-index: 100;
  margin: auto;
  top: 65px;
  right: 0px;

  .alert-card {
    right: -500px;
    transition: 0.3s ease-in-out;
    display: none;
  }

  .alert-card.open {
    display: block;
  }
}

.clear-m-0, .clear-m-0 * {
  margin: 0px;
}

//simplelightbox
$sl-font-family: $font-family-heading;
$sl-overlay-background: $black;
$sl-navigation-color: $white;
$sl-caption-color: $black;
$sl-caption-background: $white;
$sl-overlay-opacity: 0.8;

@import "~simplelightbox/src/simple-lightbox.scss";

.masonry {
    
  /* clear fix */
  .grid:after {
      content: "";
      display: block;
      clear: both;
  }

  /* ---- .grid-item ---- */
  .grid-sizer,
  .grid-item {
      width: 33.333%;
  }

  .grid-item {
      float: left;
      border: 2px solid #fff;
  }

  .grid-item img {
      display: block;
      width: 100%;
  }
}
